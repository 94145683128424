<template>
  <div
      ref="invitationEditor"
      class="invitation-editor"
  >
    <!-- Top Toolbar -->
    <div class="toolbar">
      <div class="button-menu-wrapper">
        <button
            type="button"
            class="btn-round-icon"
            @click="toggleAdditionalActionsMenu"
        >
          <i class="flaticon-ellipsis"></i>
        </button>
        <div v-if="additionalActionsMenu" class="button-menu">
          <button
              type="button"
              class="btn-round-icon"
              @click="loadCanvasState"
          >
            <i class="flaticon-arrow-down"></i>
          </button>
        </div>
      </div>
      <button
          v-if="hasLayers"
          type="button"
          class="btn-round-icon"
          @click="toggleLayerDialog"
      >
        <i class="flaticon-layers"></i>
      </button>
      <button
          type="button"
          class="btn-round-icon"
          @click="toggleStickersDialog"
      >
        <i class="flaticon-sticky-notes"></i>
      </button>
      <button
          type="button"
          class="btn-round-icon"
          @click="toggleBackgroundsDialog"
      >
        <i class="flaticon-background"></i>
      </button>
      <button
          type="button"
          class="btn-round-icon"
          @click="addText"
      >
        <i class="flaticon-typography"></i>
      </button>
    </div>

    <!-- Editor Content -->
    <div class="editor-content">
      <!-- Konva Canvas -->
      <div class="canvas-container">
        <v-stage
            v-if="showStage"
            :config="stageConfig"
            ref="stage"
        >
          <v-layer ref="layer">
            <!-- Background Image -->
            <v-image
                v-if="backgroundImage"
                :config="backgroundImageConfig"
                @mousedown="deselectElement"
                @touchstart="deselectElement"
            ></v-image>

            <!-- Dynamic Elements (Stickers, Texts) -->
            <component
                v-for="(element, index) in elements"
                :key="index"
                :is="element.type === 'image' ? 'v-image' : 'v-text'"
                :config="element.config"
                v-show="element.visible"
                @click="selectElement(element)"
                @touchstart="selectElement(element)"
            ></component>

            <!-- Transformer -->
            <v-transformer ref="transformer"></v-transformer>
          </v-layer>
        </v-stage>
      </div>
    </div>
    <!-- Sidebar Layers -->
    <div
        v-if="layersDialog"
        class="layers-selection-dialog"
    >
      <div class="dialog-header">
        <button
            type="button"
            class="btn-round-icon"
            @click="closeLayerDialog"
        >
          <i class="flaticon-close-1"></i>
        </button>
      </div>
      <div class="dialog-content">
        <ion-list>
          <ion-item
              v-for="(layerItem, index) in elements"
              :key="index"
              :class="{ active: selectedElement === layerItem }"
          >
            <ion-button
                slot="start"
                fill="clear"
                size="small"
                shape="round"
                @click.stop="selectElement(layerItem)"
            >
              <ion-icon slot="icon-only" v-if="isSelectedElement(layerItem)" :icon="icons.radioButtonOn"></ion-icon>
              <ion-icon slot="icon-only" v-else :icon="icons.radioButtonOff"></ion-icon>
            </ion-button>
            <ion-label>{{ layerItem.name }}</ion-label>
            <ion-button
                slot="end"
                fill="clear"
                size="small"
                shape="round"
                @click.stop="layerItem.visible = !layerItem.visible"
            >
              <ion-icon v-if="layerItem.visible" :icon="icons.eye"></ion-icon>
              <ion-icon v-else :icon="icons.eyeOff"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </div>
    </div>
    <!-- Stickers -->
    <div
        v-if="stickersDialog"
        class="stickers-selection-dialog"
    >
      <div class="dialog-header">
        <button
            type="button"
            class="btn-round-icon"
            @click="closeStickersDialog"
        >
          <i class="flaticon-close-1"></i>
        </button>
      </div>
      <div class="dialog-content">
        <img v-for="(sticker, stickerIndex) in stickers" :key="stickerIndex" :src="sticker.url" @click="addSticker(sticker)" />
      </div>
    </div>
    <!-- Backgrounds -->
    <div
        v-if="backgroundsDialog"
        class="backgrounds-selection-dialog"
    >
      <div class="dialog-header">
        <button
            type="button"
            class="btn-round-icon"
            @click="closeBackgroundsDialog"
        >
          <i class="flaticon-close-1"></i>
        </button>
      </div>
      <div class="dialog-content">
        <img v-for="(bg, bgIndex) in backgrounds" :key="bgIndex" :src="bg.url" @click="setBackground(bg)" />
      </div>
    </div>
    <!-- Text Properties -->
    <div v-if="selectedElement && selectedElement.type === 'text'" class="text-properties">
      <ion-list>
        <ion-list-header>מאפייני טקסט</ion-list-header>
        <ion-item>
          <ion-label>פונט</ion-label>
          <ion-select
              v-model="selectedElement.config.fontFamily"
              @change="loadFont(selectedElement.config.fontFamily)"
          >
            <ion-select-option v-for="(font, fontIndex) in googleFonts" :key="fontIndex" :value="font.value">{{ font.label }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label>גודל</ion-label>
          <ion-input type="number" v-model="selectedElementFontSize"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>טקסט</ion-label>
          <ion-input type="text" v-model="selectedElement.config.text"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>צבע</ion-label>
          <ion-input type="color" v-model="selectedElement.config.fill"></ion-input>
        </ion-item>
      </ion-list>
    </div>
    <div v-if="selectedElement && selectedElement.type === 'image'" class="stickers-properties">
      <ion-list>
        <ion-list-header>מאפייני מדבקה</ion-list-header>
        <ion-item>
          <ion-label>גודל</ion-label>
          <ion-input type="number" v-model="selectedElement.config.fontSize"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>צבע</ion-label>
          <ion-input type="color" v-model="selectedElement.config.fill"></ion-input>
        </ion-item>
      </ion-list>
    </div>
  </div>
</template>
<script>
import {
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonInput,
  IonListHeader
} from "@ionic/vue";
import VueKonva from "vue-konva";
import {eye, eyeOff, radioButtonOff, radioButtonOn, addCircleOutline} from 'ionicons/icons';
import WebFont from 'webfontloader';
import { v4 as uuidv4 } from 'uuid';


export default {
  props: {
    invitationDesign: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: '',
          settings: {},
        };
      },
    },
  },
  components: {
    IonIcon,
    IonCheckbox,
    IonLabel,
    IonSelect,
    IonList,
    IonItem,
    IonSelectOption,
    IonButton,
    IonInput,
    IonListHeader,
    VStage: VueKonva.Stage,
    VLayer: VueKonva.Layer,
    VImage: VueKonva.Image,
    VGroup: VueKonva.Group,
    VTransformer: VueKonva.Transformer,
  },
  data() {
    return {
      showStage: false,
      icons: {
        radioButtonOn,
        radioButtonOff,
        eye,
        eyeOff,
        addCircleOutline
      },
      stageConfig: {
        width: 600,
        height: 800,
      },
      backgroundImage: null,
      backgroundImageConfig: {
        x: 0,
        y: 0,
        width: 600,
        height: 800,
        image: null,
        name: 'Background',
        draggable: false,
        src: null,
        kind: 'background',
      },
      elements: [], // Array to hold stickers and text elements
      selectedElement: null,
      selectedShape: null,
      clipboard: null, // For copy/paste functionality
      backgrounds: [
        // Array of background images
        { url: '/assets/bg-colors/white-bg.jpg' },
        { url: '/assets/invitations/invitation1/bg.jpg' },
        { url: '/assets/bg-colors/black-bg.jpg' },
        // Add your background images here
      ],
      stickers: [
        // Array of sticker images
        { url: '/assets/invitations/invitation1/and.png' },
        // Add your sticker images here
      ],
      googleFonts: [
        {
          value: "Roboto",
          label: "Roboto",
        },
        {
          value: "Open Sans",
          label: "Open Sans",
        },
        {
          value: "Lato",
          label: "Lato",
        },
        {
          value: "Montserrat",
          label: "Montserrat",
        },
        {
          value: "Oswald",
          label: "Oswald",
        },
      ],
      backgroundsDialog: false,
      stickersDialog: false,
      layersDialog: false,
      additionalActionsMenu: false,
      autoSaveInterval: null,
    };
  },
  computed: {
    selectedElementFontSize: {
      get(){
        return this.selectedElement.config.fontSize
      },
      set(value){
        // make sure the selected element font size is a number
        this.selectedElement.config.fontSize = parseInt(value, 10)
      }
    },
    transformerNode() {
      return this.$refs.transformer.getNode()
    },
    hasLayers() {
      return this.elements.length > 0
    },
    canvasWidth:{
      get() {
        return this.stageConfig.width
      },
      set(value){
        this.stageConfig.width = value
        this.backgroundImageConfig.width = value
      }
    },
    canvasHeight:{
      get() {
        return this.stageConfig.height
      },
      set(value){
        this.stageConfig.height = value
        this.backgroundImageConfig.height = value
      }
    },
  },
  methods: {
    // Background Functions
    setBackground(bg) {
      this.closeBackgroundsDialog();
      const imageObj = new Image();
      imageObj.crossOrigin = 'Anonymous'; // Enable CORS if images are from external sources
      imageObj.src = bg.url;
      imageObj.onload = () => {
        this.backgroundImage = imageObj;
        this.backgroundImageConfig.image = imageObj;
        this.backgroundImageConfig.src = bg.url;

      };
    },
    // Sticker Functions
    addSticker(sticker, existingElement = {}) {
      this.closeStickersDialog();
      const imageObj = new Image();
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = existingElement.url || sticker.url;
      imageObj.onload = () => {
        const stickerElement = {
          type: 'image',
          name: `Sticker ${this.elements.length + 1}`,
          visible: true,
          config: {
            x: existingElement.x || 50,
            y: existingElement.y || 50,
            image: imageObj,
            draggable: true,
            rotation: existingElement.rotation || 0,
            name: existingElement.name || `Sticker ${this.elements.length + 1}`,
            id: existingElement.id || uuidv4(),
            src: existingElement.url || sticker.url,
            kind: 'sticker',
          },
        };
        if(existingElement.width){
          stickerElement.config.width = existingElement.width;
        }
        if(existingElement.height){
          stickerElement.config.height = existingElement.height;
        }
        const length = this.elements.push(stickerElement);
        stickerElement.config.index = length - 1;
        this.addTransformEvents(stickerElement);
      };
    },
    // Text Functions
    addText(existingElement = {}) {
      if(existingElement && existingElement.fontSize && typeof existingElement.fontSize === 'string'){
        existingElement.fontSize = parseInt(existingElement.fontSize, 10)
      }
      const textElement = {
        type: 'text',
        name: `Text ${this.elements.length + 1}`,
        visible: true,
        config: {
          x: 100,
          y: 100,
          text: 'Your Text Here',
          fontSize: 24,
          fontFamily: 'Roboto',
          fill: 'black',
          draggable: true,
          rotation: 0,
          name: `Text ${this.elements.length + 1}`,
          id: uuidv4(),
          kind: 'text',
          ...existingElement
        },
      };
      this.loadFont('Roboto');
      const length = this.elements.push(textElement);
      textElement.config.index = length - 1;
      this.addTransformEvents(textElement);
    },
    loadFont(font) {
      WebFont.load({
        google: {
          families: [font],
        },
        active: () => {
          this.$refs.layer.getStage().batchDraw();
        },
      });
    },
    // Element Selection
    selectElement(element) {
      if(this.selectedElement !== element) {
        this.selectedElement = element;
        // Update transformer configuration
        const layer = this.$refs.layer.getNode();
        const shape = layer.children.find((child) => child.attrs.id === element.config.id);
        this.selectedShape = shape;

        // Update the transformer
        this.$nextTick(() => {
          this.transformerNode.nodes([shape]);
        });
      }
    },
    isSelectedElement(element) {
      return this.selectedElement === element;
    },
    deselectElement() {
      this.selectedElement = null;
      this.selectedShape = null;
      this.transformerNode.nodes([]);
    },
    // Transform Functions
    addTransformEvents(element) {
      const config = element.config;
      config.onTransform = () => {
        // Keep the element within bounds
        const shape = this.selectedShape;
        const scaleX = shape.scaleX();
        const scaleY = shape.scaleY();
        shape.scaleX(1);
        shape.scaleY(1);
        shape.width(shape.width() * scaleX);
        shape.height(shape.height() * scaleY);
      };
    },
    // Copy/Paste/Duplicate/Delete Functions
    copyElement() {
      if (this.selectedElement) {
        this.clipboard = JSON.parse(JSON.stringify(this.selectedElement));
      }
    },
    pasteElement() {
      if (this.clipboard) {
        const newElement = JSON.parse(JSON.stringify(this.clipboard));
        newElement.name = `${newElement.name} Copy`;
        newElement.config.x += 10;
        newElement.config.y += 10;
        this.elements.push(newElement);
      }
    },
    duplicateElement() {
      if (this.selectedElement) {
        const newElement = JSON.parse(JSON.stringify(this.selectedElement));
        newElement.name = `${newElement.name} Copy`;
        newElement.config.x += 10;
        newElement.config.y += 10;
        this.elements.push(newElement);
      }
    },
    deleteElement() {
      if (this.selectedElement) {
        const index = this.elements.indexOf(this.selectedElement);
        if (index > -1) {
          this.elements.splice(index, 1);
          this.selectedElement = null;
        }
      }
    },
    transformBounds(oldBox, newBox) {
      const stageWidth = this.stageConfig.width;
      const stageHeight = this.stageConfig.height;

      // Ensure element stays within bounds
      if (
          newBox.x < 0 ||
          newBox.y < 0 ||
          newBox.x + newBox.width > stageWidth ||
          newBox.y + newBox.height > stageHeight ||
          newBox.width < 20 ||
          newBox.height < 20
      ) {
        return oldBox;
      }
      return newBox;
    },
    initDefaultBackground() {
      const defaultBg = this.backgrounds[0];
      this.setBackground(defaultBg);
    },
    toggleBackgroundsDialog() {
      this.backgroundsDialog = !this.backgroundsDialog;
    },
    closeBackgroundsDialog() {
      this.backgroundsDialog = false;
    },
    toggleStickersDialog() {
      this.stickersDialog = !this.stickersDialog;
    },
    closeStickersDialog() {
      this.stickersDialog = false;
    },
    toggleLayerDialog() {
      this.layersDialog = !this.layersDialog;
    },
    closeLayerDialog() {
      this.layersDialog = false;
    },
    matchCanvasSize(){
      // get screen width from media query
      const mediaQuery = window.matchMedia('(max-width: 600px)');
      // get invitationEditor width and set the canvas width and height accordingly, the aspect need to match 600w/800h
      const width = window.innerWidth;
      const height = width * 800 / 600;
      if(mediaQuery.matches){
        this.canvasWidth = width;
        this.canvasHeight = height;
      }
    },
    saveCanvasState() {
      // periodic saving of the canvas state for cases of a browser crash or user accidentally closing the tab
      const state = this.$refs.stage.getNode().toJSON();
      console.log('Canvas state saved',{
        state
      });
      localStorage.setItem('invitationEditorState', state);
    },
    loadCanvasState() {
      // load the canvas state from the invitation design or localStorage
      let canvasState;
      if(this.invitationDesign && this.invitationDesign.settings && this.invitationDesign.settings.state){
        canvasState = this.invitationDesign.settings.state;
        console.log('Canvas state loaded from the invitation design');
      }else{
        const state = localStorage.getItem('invitationEditorState');
        if(state){
          try{
            canvasState = JSON.parse(state);
            console.log('Canvas state loaded from localStorage');
          }catch (e){
            console.error('Error parsing canvas state from localStorage', e);
          }
        }else{
          console.warn('No canvas state found in the invitation design or localStorage');
        }
      }
      if (canvasState && canvasState.children) {
        console.log({
          canvasState
        })
        this.elements = [];
        canvasState.children.forEach((layer) => {
          layer.children.forEach((element) => {
            if(!element.attrs || !element.attrs.kind){
              return;
            }
            switch (element.attrs.kind){
              case 'background':
                if(!element.attrs.src){
                  console.warn('No background image found in the canvas state');
                }
                this.setBackground({ url: element.attrs.src });
                break;
              case 'sticker':
                if(!element.attrs.src){
                  console.warn('No sticker image found in the canvas state');
                }
                this.addSticker({}, {
                  x: element.attrs.x,
                  y: element.attrs.y,
                  width: element.attrs.width,
                  height: element.attrs.height,
                  rotation: element.attrs.rotation,
                  url: element.attrs.src,
                  id: element.attrs.id,
                });
                break;
              case 'text':
                this.addText({
                  x: element.attrs.x,
                  y: element.attrs.y,
                  text: element.attrs.text,
                  fontSize: element.attrs.fontSize,
                  fontFamily: element.attrs.fontFamily,
                  fill: element.attrs.fill,
                  draggable: true,
                  rotation: element.attrs.rotation,
                  name: element.attrs.name,
                  id: element.attrs.id,
                });
                break;
            }
          });
        });
      }
    },
    toggleAdditionalActionsMenu() {
      this.additionalActionsMenu = !this.additionalActionsMenu;
    },
    saveInvitationDesign() {
      const state = JSON.parse(this.$refs.stage.getNode().toJSON());
      let settings;
      if(this.invitationDesign && this.invitationDesign.settings){
        settings = {
          ...this.invitationDesign.settings,
          state,
        }
      }else{
        settings = {
          state,
        }
      }
      const imageBlob = this.dataUrlToBlob(this.$refs.stage.getNode().toDataURL());
      this.$emit('save', {
        ...this.invitationDesign,
        settings,
        thumbnail: imageBlob,
      });
    },
    dataUrlToBlob(dataUrl) {
      const parts = dataUrl.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
  },
  watch: {
    invitationDesign: {
      handler(){
        this.loadCanvasState();
      },
      deep: true,
    },
  },
  mounted() {
    // Load default font
    this.matchCanvasSize();
    this.loadFont('Roboto');
    this.initDefaultBackground();
    this.loadCanvasState();
    this.$nextTick(() => {
      this.showStage = true;
    });

    // Auto save every 10 seconds to prevent data loss
    /*this.autoSaveInterval = setInterval(() => {
      this.saveCanvasState();
    }, 10000);*/
    console.log('editor canvas mounted');
  },
  unmounted() {
    console.log('editor canvas unmounted');
    clearInterval(this.autoSaveInterval);
  }
}


</script>
<style scoped>
.canvas-container {
  touch-action: none; /* Prevent default touch behaviors */
  -ms-touch-action: none; /* For IE11 */
}
.invitation-editor {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #000;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  background-color: transparent;
  position: relative;
  z-index: 1;
}

.editor-content {
  display: flex;
  flex: 1;
  position: relative;
}

.canvas-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assets-panel {
  width: 200px;
  border-left: 1px solid #ccc;
  padding: 10px;
}

.assets-panel img {
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
}

.text-properties {
  padding: 10px;
  background-color: #fafafa;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
}

.text-properties label {
  display: block;
  margin-bottom: 5px;
}

.text-properties input,
.text-properties select {
  width: 100%;
}
button.btn-round-icon {
  background-color: #000;
  font-size: 2rem;
  border-radius: 50px;
  padding: 4px 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  gap: 0;
}
.button-menu-wrapper {
  position: relative;
}

.button-menu-wrapper .button-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
  display: flex;
  flex-flow: column;
}

.backgrounds-selection-dialog,
.stickers-selection-dialog,
.layers-selection-dialog {
  top: 0;
  right: 0;
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: #2B2726;
  z-index: 1;
  overflow-y: auto;
}
.backgrounds-selection-dialog .dialog-header,
.stickers-selection-dialog .dialog-header,
.layers-selection-dialog .dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: #000;
  height: 70px;
  padding: 10px;
}
.backgrounds-selection-dialog .dialog-content,
.stickers-selection-dialog .dialog-content,
.layers-selection-dialog .dialog-content {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 15px;
}
</style>